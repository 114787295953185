<template>
  <div id="purviewSetting" :style="addrolee ? 'margin-bottom:54px' : ''">
    <div class="leftMenu" v-if="!isAddTeacher">
      <div class="addrole" @click="addrole">
        <span>
          <img src="~@ass/img/1.4.2/icon_xjfz备份@2x.png" alt="" />
        </span>
        <span class="ml10">添加角色</span>
      </div>

      <div class="tree">
        <el-tree
          v-if="selected"
          :data="data"
          default-expand-all
          ref="tree"
          :props="defaultProps"
          @node-click="handleNodeClick"
          :highlight-current="true"
          :check-on-click-node="true"
          node-key="jg_group_id"
        >
          <span
            class="my-custom"
            slot-scope="{ node, data }"
            @click="handleNodeClick(data)"
          >
            <img :src="data.img" alt="" />
            <span class="label" :title="node.label">
              {{ node.label }}
              {{
                data.isfirst ? '(' + (data.count ? data.count : '0') + ')' : ''
              }}
            </span>
          </span>
        </el-tree>
      </div>
    </div>

    <div class="right">
      <div class="top">
        <!-- 添加角色 -->
        <el-form
          v-if="addrolee"
          :model="addlist"
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
          class="demo-ruleForm"
          label-position="left"
        >
          <el-form-item label="角色名称" prop="name" class="mb30">
            <el-input
              placeholder="请输入角色名称"
              v-model="addlist.name"
              maxlength="12"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="描述">
            <span slot="label" class="ml15">描述</span>
            <el-input
              type="textarea"
              v-model="addlist.describe"
              maxlength="60"
              placeholder="请输入角色描述，选填"
              show-word-limit
              :autosize="{ minRows: 3 }"
            ></el-input>
          </el-form-item>
        </el-form>
        <!-- 角色简介 -->
        <div class="introduction" v-else>
          <div class="caozuo">
            <div class="left">
              <img :src="jianjieContain.img" class="mr10" alt="" ref="img" />
              <span class="sp_2">{{ jianjieContain.name }}</span>
              <!-- 编辑 -->

              <img
                @click="edit"
                v-if="jianjieContain.edit"
                src="~@ass/img/1.4.2/icon_bjjs@2x.png"
                class="mr20 ml20 cp"
                alt=""
              />
              <!-- 删除 -->
              <img
                v-if="jianjieContain.edit"
                @click="del"
                src="~@ass/img/1.4.2/icon_scjs@2x.png"
                class="mr10 cp"
                alt=""
              />
            </div>
            <div class="chakan">
              <el-button type="text" @click="see">
                查看成员({{ jianjieContain.count }})
              </el-button>
            </div>
          </div>
          <div class="neirong">
            {{ jianjieContain.describe }}
          </div>
        </div>
      </div>
      <div class="jurisdiction">
        <div class="title">功能权限</div>
        <!-- 添加角色 -->
        <div class="select" v-if="addrolee">
          <span class="sp_1">在</span>
          <el-select
            v-model="value"
            placeholder="请选择角色"
            class="ml10 mr10"
            @change="hadjurisdiction"
          >
            <el-option
              v-for="item in options"
              :key="item.jg_group_id"
              :label="item.name"
              :value="item.jg_group_id"
            ></el-option>
          </el-select>
          <span class="sp_1">的基础上编辑权限</span>
        </div>
        <div class="maincontain">
          <div class="one">
            <el-tree
              @check="check"
              ref="tree1"
              :data="firstlist"
              show-checkbox
              node-key="menu_id"
              :props="defaultProps2"
            >
              <span class="my-custom-tree" slot-scope="{ node, data }">
                <span class="tree-label">
                  {{ node.label }}
                  <div v-if="data.is_edit == 2">
                    <template v-if="node.checked">
                      <img
                        class="tree-img"
                        src="~@ass/img/1.4.2/selected_icom.png"
                        alt=""
                      />
                    </template>
                    <template v-else>
                      <img
                        class="tree-img"
                        src="~@ass/img/1.4.2/下载 (1).png"
                        alt=""
                      />
                    </template>
                  </div>
                </span>
              </span>
            </el-tree>
          </div>

          <div class="two">
            <el-tree
              @check="check"
              ref="tree2"
              :data="twolist"
              show-checkbox
              node-key="menu_id"
              :props="defaultProps2"
            >
              <span class="my-custom-tree" slot-scope="{ node, data }">
                <span class="tree-label">
                  {{ node.label }}
                  <div v-if="data.is_edit == 2">
                    <template v-if="node.checked">
                      <img
                        class="tree-img"
                        src="~@ass/img/1.4.2/selected_icom.png"
                        alt=""
                      />
                    </template>
                    <template v-else>
                      <img
                        class="tree-img"
                        src="~@ass/img/1.4.2/下载 (1).png"
                        alt=""
                      />
                    </template>
                  </div>
                </span>
              </span>
            </el-tree>
          </div>

          <div class="three">
            <el-tree
              @check="check"
              ref="tree3"
              :data="threelist"
              show-checkbox
              node-key="menu_id"
              :props="defaultProps2"
            >
              <span class="my-custom-tree" slot-scope="{ node, data }">
                <span class="tree-label">
                  {{ node.label }}
                  <div v-if="data.is_edit == 2">
                    <template v-if="node.checked">
                      <img
                        class="tree-img"
                        src="~@ass/img/1.4.2/selected_icom.png"
                        alt=""
                      />
                    </template>
                    <template v-else>
                      <img
                        class="tree-img"
                        src="~@ass/img/1.4.2/下载 (1).png"
                        alt=""
                      />
                    </template>
                  </div>
                </span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
    </div>
    <div class="preservation" v-if="addrolee">
      <el-button size="medium" style="width: 86px" @click="cancel">
        取消
      </el-button>
      <el-button
        size="medium"
        type="primary"
        style="width: 86px"
        @click="preservation"
      >
        保存
      </el-button>
    </div>

    <!-- 删除弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="540px"
      class="dialogVisible"
    >
      <span class="dialogcontain">
        <img src="~@ass/img/1.4.2/icon_gth@2x.png" alt="" />
        <div class="title">删除角色</div>
        <div class="tip1">删除该角色后，您在邀请老师时将无法再选择该角色</div>
        <el-input v-model="input" placeholder="请输入“确认删除”"></el-input>
        <div class="tip2">在输入框输入“确认删除”来确认删除操作</div>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblecancel">取 消</el-button>
        <el-button
          type="primary"
          @click="suredel"
          :disabled="input !== '确认删除'"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>

    <!-- 查看成员 -->
    <el-dialog
      :title="jianjieContain.name"
      :visible.sync="dialogVisible2"
      width="800px"
      class="dialogVisible2"
      @close="closedialogVisible"
    >
      <span>
        <el-table
          :data="getRoleUserlist"
          style="width: 100%"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
        >
          <el-table-column prop="uname" label="老师名称" width="250">
            <template slot-scope="{ row }">
              <div class="flex-center">
                <img
                  :src="row.uphoto"
                  alt=""
                  style="
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    margin-right: 10px;
                  "
                />
                <span>{{ row.uname }}</span>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="name" label="加入机构时间" width="180">
            <template slot-scope="{ row }">
              {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" align="right">
            <template slot-scope="{ row }">
              <div class="popover" v-if="row.uid != userInfo.uid">
                <el-popover
                  placement="bottom-end"
                  width="230px"
                  trigger="click"
                >
                  <div>
                    <el-checkbox-group v-model="checkList">
                      <p v-for="item in userRoles" :key="item.jg_group_id">
                        <el-checkbox
                          :label="item.jg_group_id"
                          :checked="item.is_select"
                        >
                          {{ item.name }}
                        </el-checkbox>
                      </p>
                    </el-checkbox-group>
                    <div class="popoverbts">
                      <el-button size="medium" @click="cancelPopover">
                        取消
                      </el-button>
                      <el-button
                        type="primary"
                        size="medium"
                        @click="checkChange2"
                      >
                        确定
                      </el-button>
                    </div>
                  </div>
                  <el-button
                    type="text"
                    slot="reference"
                    @click="editquanxian(row.uid)"
                  >
                    编辑权限
                  </el-button>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </span>
    </el-dialog>
    <el-button id="popo-btn" ref="popo" />
  </div>
</template>

<script>
import background from '@/mixin/background'
import { mapState } from 'vuex'

export default {
  name: 'purviewSetting',
  props:{
    isAddTeacher:{
      type:Boolean,
      default:false,
    },
  },
  data() {
    return {
      customRole: '',

      isselectUid: '', //查看角色成员-编辑学员时选中的id

      userRoles: [], //角色编辑权限

      getRoleUserlist: undefined, //角色成员列表

      isAdd: undefined, //false编辑，true添加

      authListval: [], //获取功能权限的全部数据

      defaultProps2: {
        children: 'menu',
        label: 'name',
      },

      input: '', //确认删除角色的字段

      //添加角色的内容
      addlist: {
        name: '',
        describe: '',
        menu_ids: '',
      },

      addrolee: false, //控制添加角色的显示

      jianjieContain: {}, //被选中角色的列表

      select: true,

      selected: '', //左侧默认选中节点的id

      checkList: [],

      // 选中的权限ID
      checkedIdList: [],

      dialogVisible: false,

      dialogVisible2: false,

      data: [
        {
          jg_group_id: Math.random() * 1000,
          name: '默认角色',
          img: require('@ass/img/1.4.2/icon_jstb_mr@2x.png'),
          count: '',
          isfirst: 1,
          children: [],
        },
        {
          jg_group_id: Math.random() * 1000,
          name: '自定义角色',
          isfirst: 1,
          img: require('@ass/img/1.4.2/icon_jstb_zd@2x.png'),
          count: '',
          children: [],
        },
      ],

      defaultProps: {
        children: 'children',
        label: 'name',
        count: 'count',
        img: 'img',
      },

      rules: {
        name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
      },

      options: [],

      value: '',
    }
  },

  watch: {
    checkedIdList() {
      this.$refs.tree1.setCheckedKeys(this.checkedIdList)
      this.$refs.tree2.setCheckedKeys(this.checkedIdList)
      this.$refs.tree3.setCheckedKeys(this.checkedIdList)
    },
  },

  computed: {
    ...mapState(['userInfo']),
    firstlist() {
      return this.authListval.slice(0, 4)
    },

    twolist() {
      return this.authListval.slice(4, 8)
    },

    threelist() {
      return this.authListval.slice(8)
    },
  },

  created() {
    this.getroleList()
    if(this.isAddTeacher){
      this.$nextTick(()=>{
        this.checkedIdList = []
        this.addrolee = true
        this.isAdd = true
      })
    }
  },

  methods: {
    cancelPopover() {
      this.$refs.popo.$el.click()
    },

    //查看学员关闭的回调
    closedialogVisible() {
      //1是查看成员弹窗关闭时，数据重新请求
      this.getroleList(1)
    },

    //选择已有权限进行编辑
    hadjurisdiction(val) {
      if (val == 0) {
        this.checkedIdList = []
        return
      }
      this.selected = val
      this.getCgeckedIdList()
    },

    //确认删除角色
    suredel() {
      this.$http({
        url: '/Auth/delRole',
        data: { jg_group_id: this.jianjieContain.jg_group_id },
        callback: ({ info }) => {
          this.$root.prompt({
            msg: info,
            type: 'success',
          })
          this.dialogVisible = false
          this.input = ''
          this.getroleList()
        },
      })
      // this.getroleList()
    },

    dialogVisiblecancel() {
      this.input = ''
      this.dialogVisible = false
    },

    //查看成员-编辑权限
    checkChange2() {
      if (this.checkList.length == 0) {
        this.$root.prompt('请选择角色！')
      } else {
        this.$http({
          url: '/Auth/editRoleUser',
          data: { jg_group_id: this.checkList, uid: this.isselectUid },
          callback: () => {
            this.$refs.popo.$el.click()
            this.seelist()
          },
        })
      }
    },

    //编辑权限按钮
    editquanxian(val) {
      this.isselectUid = val
      this.checkList = []
      this.userRoles = []
      this.$http({
        url: '/Auth/userRolesList',
        data: { uid: val },
        callback: ({ data }) => {
          this.userRoles = data
          this.$nextTick(() => {
            this.checkList = data
              .filter(item => item.is_select)
              .map(item => item.jg_group_id)
          })
        },
      })
    },

    //编辑
    edit() {
      this.addlist = this.jianjieContain
      this.addrolee = true
      this.getauthList(1)
    },

    //保存
    preservation() {
      this.addlist.name = this.addlist.name.trim()

      // 是否勾选直播课
      const liveCourse = this.checkedIdList.findIndex(item => item == 10) > -1
      //是否勾选公开课
      const openCourse = this.checkedIdList.findIndex(item => item == 201) > -1
      // 如果直播课或公开课有一个勾选，那么自动勾选课程安排
      if (liveCourse || openCourse) {
        this.checkedIdList.push('58')
      }

      const data = _.merge({}, this.addlist, {
        img: '',
        menu_ids: this.checkedIdList.join(','),
      })
      if (this.addrolee) {
        this.$refs['ruleForm'].validate(valid => {
          if (valid) {
            if (this.checkedIdList.length == 0) {
              this.$root.prompt('请至少选择一项权限')
              return false
            }
            if (!this.isAdd) {
              // 编辑
              this.$http({
                url: '/auth/editRole',
                data: data,
                callback: ({ info }) => {
                  this.$root.prompt({
                    type: 'success',
                    msg: info,
                  })
                  this.value = ''
                  this.getroleList(1)
                },
              })
            } else {
              //新建
              this.$http({
                url: '/auth/addRole',
                data: data,
                callback: ({ info }) => {
                  this.$root.prompt({
                    type: 'success',
                    msg: info,
                  })
                  this.value = ''
                  this.getroleList(2)
                  if(this.isAddTeacher){
                    this.$emit("AddTeacherOk")
                  }
                },
              })
            }
          } else {
            return false
          }
        })
      }
    },

    check(data) {
      let ref
      let node
      let viewNodeId
      var check1 = this.$refs.tree1.getCheckedKeys()
      var check2 = this.$refs.tree2.getCheckedKeys()
      var check3 = this.$refs.tree3.getCheckedKeys()
      this.checkedIdList = check1.concat(check2, check3)
      for (var key in this.$refs) {
        const item = this.$refs[key]
        if (
          item &&
          'getNode' in item &&
          typeof item.getNode === 'function' &&
          (node = item.getNode(data.menu_id))
        ) {
          ref = item
          break
        }
      }

      try {
        viewNodeId = node.parent.data.menu.find(item => item.is_edit == 2)
          .menu_id
      } catch (e) {
        console.log('同级节点中没有查看选项')
      }

      if (viewNodeId) {
        this.checkedIdList.push(viewNodeId)
        ref.setCheckedKeys(this.checkedIdList)
      }
    },

    //取消
    cancel() {
      if(this.isAddTeacher){
        this.$emit("cancel")
        return
      }
      this.getroleList(1)
      this.addrolee = false
    },

    //添加角色
    addrole() {
      if (Number(this.customRole) >= 25) {
        this.$root.prompt('自定义角色数量最多添加25个！')
        return
      }
      this.value = ''
      this.checkedIdList = []

      this.addlist = {
        name: '',
        describe: '',
        menu_ids: '',
      }
      // 1是新建或者编辑
      this.getauthList(1)
      this.$refs.tree.setCurrentKey(null)
      this.addrolee = true
      this.isAdd = true
    },

    //角色列表
    getroleList(val) {
      // val 1,编辑的后重新获取角色列表  2，新建保存后的重新获取列表
      this.$http({
        url: '/Auth/roleList',
        data: {},
        callback: ({ code, data }) => {
          if (code == 200) {
            this.data.forEach((item, index) => {
              if (index == 0) {
                item.children = data.defaultRole
                item.count = data.defaultRole.length

                item.children = item.children.map(item => {
                  return _.assign({}, item, {
                    img: require('@ass/img/1.4.2/icon_jstb_mr@2x.png'),
                    edit: false,
                  })
                })

                this.selected = item.children[0].jg_group_id
              } else {
                item.children = data.customRole
                item.count = data.customRole.length
                item.children = item.children.map(item => {
                  return _.assign({}, item, {
                    img: require('@ass/img/1.4.2/icon_jstb_zd@2x.png'),
                    edit: true,
                  })
                })
              }
            })
            this.options = [...data.defaultRole, ...data.customRole]
            this.options.unshift({
              jg_group_id: 0,
              name: '无',
            })
            if (val == 1) {
              this.handleNodeClick(this.jianjieContain)
            } else if (val == 2) {
              this.handleNodeClick(
                this.data[1].children[this.data[1].children.length - 1]
              )
            } else {
              this.handleNodeClick(this.data[0].children[0])
            }

            this.$nextTick(() => {
              if(this.isAddTeacher){
                this.getauthList(1)
              }else{
                this.getauthList()
              }
              this.getCgeckedIdList()
            })
            this.customRole = data.customRole.length
          }
        },
      })
    },

    //获取功能权限列表
    getauthList(val) {
      this.$http({
        url: '/Auth/authList',
        data: { jg_group_id: this.selected },
        callback: ({ code, data }) => {
          if (code == 200) {
            if (val !== 1) {
              this.authListval = this.setDisabled(data)
            } else {
              //设置含有’查看‘ 的为disalbe属性
              this.authListval = data
            }
          }
        },
      })
    },

    //设置disabled的属性设置全部为disable属性
    setDisabled(val) {
      val.forEach(item => {
        item.disabled = true

        if (item.menu) {
          this.setDisabled(item.menu)
        }
      })
      return val
    },

    // 获取选中的节点menu_id
    getCgeckedIdList() {
      this.$http({
        url: '/Auth/getRoleAuth',
        data: { jg_group_id: this.selected },
        callback: ({ code, data }) => {
          if (code == 200) {
            this.checkedIdList = data ? data.split(',') : []
          }
        },
      })
    },

    handleNodeClick(val1) {
      if(this.isAddTeacher) return
      this.value = ''
      this.isAdd = false
      this.addrolee = false
      this.addlist = {}
      this.selected = val1.jg_group_id
      this.setDisabled(this.authListval)
      this.seelist().then(() => {
        if (!val1.isfirst) {
          this.jianjieContain = _.merge({}, val1, {
            count: this.getRoleUserlist.length,
          })
          this.$refs.tree.setCurrentKey(val1.jg_group_id)
          this.getCgeckedIdList()
        } else {
          this.$refs.tree.setCurrentKey(this.jianjieContain.jg_group_id)
        }
      })
    },

    //删除角色
    del() {
      if (this.jianjieContain.count > 0) {
        this.$alert(
          '当前有账号为该角色的成员，无法删除，请将这些成员修改为其他角色后再尝试删除！',
          '',
          {
            confirmButtonText: '确定',
            callback: () => {},
          }
        )
        return
      }
      this.dialogVisible = true
    },

    //查看成员
    see() {
      this.dialogVisible2 = true
      this.$nextTick(() => {
        this.seelist()
      })
    },

    //学员请求的端口
    seelist() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          this.$http({
            url: '/Auth/getRoleUser',
            data: { jg_group_id: this.selected },
            callback: ({ data }) => {
              this.getRoleUserlist = []
              this.getRoleUserlist = data
              resolve()
            },
            error: () => {
              this.$root.prompt('操作失败')
              reject()
            },
          })
        })
      })
    },
  },

  mixins: [background],
}
</script>

<style lang="less" scoped>
#popo-btn {
  width: 0;
  height: 0;
  border: none;
  padding: 0;
  margin: 0;
}
::v-deep .el-table:before {
  height: 0;
}
::v-deep .el-checkbox-group {
  height: 172px;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #fff;
}

::v-deep .dialogVisible2 {
  .el-dialog__title {
    font-size: 14px;
    color: #333333;
    line-height: 14px;
  }
  .el-dialog__body {
    height: 465px;
  }
  .el-table--fit {
    height: 100%;
    overflow: auto;
  }
}

::v-deep .dialogVisible {
  .el-dialog__footer {
    text-align: center;
  }
}
.el-checkbox {
  margin-bottom: 20px;
}
::v-deep p:last-child {
  .el-checkbox {
    margin-bottom: 0px;
  }
}

::v-deep .el-tree > .el-tree-node:focus > .el-tree-node__content {
  background-color: transparent !important;
}
::v-deep .el-popper {
  padding: 20px 0 !important;
}
.tree-label {
  font-size: 14px;
  position: relative;
  display: block;
}
.tree-img {
  width: 15px;
  height: 15px;
  cursor: no-drop;
  position: absolute;
  left: -22px;
  top: 0px;
  z-index: 2;
}
.popoverbts {
  border-top: 1px solid #f5f5f5;
  padding-top: 9px;
  margin-top: 20px;
}
#purviewSetting {
  background-color: #fff;
  margin: 20px;
  padding: 20px;
  display: flex;
  .dialogcontain {
    img {
      margin: 0 auto;
      height: 60px;
      width: 60px;
    }
    .title {
      font-size: 18px;
      color: #333333;
      line-height: 18px;
      text-align: center;
      margin-top: 20px;
    }
    .el-input {
      width: 322px;
      margin-left: 89px;
    }
    .tip1 {
      margin: 10px 0 20px;
      font-size: 14px;
      color: #666666;
      line-height: 14px;
      text-align: center;
    }
    .tip2 {
      margin: 10px 0 0px;
      font-size: 12px;
      color: #ff3535;
      line-height: 12px;
      text-align: center;
      transform: translateX(-53px);
    }
  }
  .leftMenu {
    margin-right: 20px;
    width: 264px;
    overflow: auto;
    overflow-x: hidden;
    border: 1px solid #e4e4e4;
    .tree {
      padding: 5px;
    }
    .addrole {
      align-items: center;
      display: flex;
      padding: 11px;
      justify-content: center;
      font-size: 13px;
      color: #333333;
      border-bottom: 1px solid #e4e4e4;
      cursor: pointer;
      img {
        height: 14px;
        width: 14px;
      }
    }
    ::v-deep .el-tree-node__content {
      height: 36px;
    }

    ::v-deep .el-tree-node__expand-icon {
      font-size: 12px;
      padding: 0px;
    }

    ::v-deep .el-tree-node:focus > .el-tree-node__content {
      background: #ebf8f7;
      // color: #0AA29B;
    }

    ::v-deep .el-tree-node__content:hover {
      background: #ebf8f7;
      // color: #0AA29B;
    }

    ::v-deep .el-tree-node:focus > .el-tree-node__content {
      background: #ebf8f7;
    }

    .my-custom {
      display: flex;
      margin-left: 2px;
      height: 30px;
      width: 202px;

      img {
        width: 15px;
        height: 15px;
        margin-right: 11px;
        margin-top: 7px;
      }

      .label {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        color: #333333;
        line-height: 30px;
      }
    }
  }
  .right {
    width: calc(100% - 280px);
    .top {
      padding: 20px;
      width: 100%;
      background: #f5f5f5;
      box-sizing: border-box;
      margin-bottom: 30px;
      .introduction {
        .caozuo {
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            display: flex;
            img {
              height: 16px;
              width: auto;
            }
            .sp_2 {
              font-size: 16px;
              color: #333333;
            }
          }
        }
        .neirong {
          font-size: 13px;
          color: #666666;
          line-height: 18px;
        }
      }
    }
    .jurisdiction {
      .title {
        font-size: 16px;
        color: #333333;
        line-height: 16px;
        margin-bottom: 20px;
      }
      .select {
        margin-bottom: 20px;
        .sp_1 {
          font-size: 13px;
          color: #333333;
          line-height: 13px;
        }
      }
      .maincontain {
        border: 1px solid #e4e4e4;
        display: flex;

        .two {
          border-right: 1px solid #e4e4e4;
          border-left: 1px solid #e4e4e4;
        }

        .one,
        .two,
        .three {
          flex: 1;
          padding: 14px 3px 96px;
          ::v-deep .el-tree-node__content {
            margin-bottom: 8px;
          }
          // ::v-deep .el-tree-node__expand-icon {
          //   font-size: 22px;
          //   padding: 0px;
          // }
        }
      }
    }
  }
  .preservation {
    z-index: 3;
    position: fixed;
    height: 54px;
    background-color: #fff;
    width: calc(100% - 188px);
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.11);
  }
}
</style>
